import baseService from './baseService';

export function getWeeklySubmissions(from, to, editor) {
  return baseService.get(`/report/weekly-submissions/${from}/${to}/${editor}`);
}

export function getTopPerformer(page, size, field, sort) {
  return baseService.get('/report/top-performer', {
    headers: {
      page,
      size,
      field,
      sort,
    },
  });
}
