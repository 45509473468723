import React from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button, Divider,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Popup,
  Search,
  Label,
} from "semantic-ui-react";
import moment from "moment";
import { getPublicFiles } from "../../actions/fileActions";
import { getFilesBySearch } from "../../services/fileService";
import { getPublicFolder } from "../../actions/folderActions";
import { getSpecificPublicFolder } from "../../services/folderService";
import { downloadFile, getIconBasedOnFile, getShareFolderLink } from "../../utility";
import DownloadAs from "../DownloadAs";
import NavBar from "../Navbar";
import {
  getPublicElements,
  downloadElement,
} from "../../actions/youtubeDlActions";
import Snackbar from "../Snackbar";

import "../../containers/home.css";

class PublicMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDownloadAs: false,
      fileName: null,
      downloadURL: null,
      folderID: null,
      folderPath: [{
        folder_id: null,
        name: 'Home'
      }],
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      },
      isSearchLoading: false,
      searchResults: [],
      searchValue: '',
    };
  }

  componentDidMount() {
    const folder = new URLSearchParams(this.props.location.search).get("folder");
    const { fetchPublicFolder, fetchPublicElements } = this.props;
    if(folder) {
      getSpecificPublicFolder(folder)
      .then(data => {
        if(data.data.length) {
          this.handleFolderClick(folder, data.data[0].name);
        } else {
          this.setState({
            snackbar: {
              open: true,
              message: 'Folder you are looking for is not found!',
              success: false,
              error: true
            }
          })
        }
      }).catch(err => {
        this.setState({
          snackbar: {
            open: true,
            message: 'Unable to found folder!',
            success: false,
            error: true
          }
        })
      })
    } else {
      fetchPublicFolder(null);
      fetchPublicElements();
    }
  }

  changeFolder = (folderID, index) => {
    const { fetchPublicFiles, fetchPublicFolder } = this.props;
    this.setState(state => ({
      ...state,
      folderID,
      folderPath: state.folderPath.filter((_, i) => i <= index)
    }), () => {
      fetchPublicFiles(folderID);
      fetchPublicFolder(folderID)
    });
  }

  handleFolderClick = (folder_id, name) => {
    const { fetchPublicFiles, fetchPublicFolder } = this.props;
    this.setState(state => ({
      ...state,
      folderID: folder_id,
      folderPath: [...state.folderPath, {folder_id, name}]
    }), () => {
      fetchPublicFiles(folder_id);
      fetchPublicFolder(folder_id);
    });
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  handleSearchSelect = (e, { result }) => window.open(result.access_url, '_blank');

  handleSearchChange = (e, { value }) => {
    this.setState({ searchValue: value, searchResults: [] });
    let searchValue = value.trim();
    if (searchValue.length > 2) {
      this.setState({isSearchLoading: true});
      getFilesBySearch(searchValue)
      .then(data => {
        this.setState({
          isSearchLoading: false,
          searchResults: data.data.map((x, i) => ({title: x.name, key: x.name+i, ...x})),
        });
      }).catch(err => {
        this.setState({isSearchLoading: false});
      })
    }
  }

  shareFolderLink = ({id, permission}) => {
    navigator.clipboard.writeText(getShareFolderLink(id, permission));
    this.setState({
      snackbar: {
        open: true,
        message: 'Copied to clipboard!',
        success: true,
        error: false
      }
    });
  }

  render() {
    const { downloadYoutubeFile } = this.props;
    const { openDownloadAs, fileName, downloadURL, folderID, folderPath, snackbar, isSearchLoading, searchResults, searchValue } = this.state;
    return (
      <NavBar>
        <Grid className="home-container">
          <GridColumn
            className="main-col"
            mobile={16}
            tablet={12}
            computer={16}
          >
            <div className="main-details" style={{ height: "100%" }}>
              <Search
                className='search-file'
                input={{ icon: 'search', iconPosition: 'left' }}
                loading={isSearchLoading}
                onResultSelect={this.handleSearchSelect}
                onSearchChange={this.handleSearchChange}
                results={searchResults}
                value={searchValue}
                noResultsMessage={searchValue.length > 2 ? 'No file found!' : 'Please type 3 or more characters to serach file!'}
              />
              <Divider/>
              <Breadcrumb size="huge" className="custom-breadcrumb">
                {
                  folderPath.map((f, i) => <>
                    <Breadcrumb.Section
                      key={i}
                      active={i === folderPath.length - 1}
                      link
                      onClick={() => this.changeFolder(f.folder_id, i)}
                    >
                      <span className="unselectable">{f.name}</span>
                    </Breadcrumb.Section>
                    {
                      i !== folderPath.length - 1 && <Breadcrumb.Divider icon='right chevron'/>
                    }
                    {
                      f.name !== 'Home' && i === folderPath.length - 1 &&
                      <Button
                      basic
                      circular
                      icon='share'
                      size='mini'
                      title={`Copy Link of ${f.name} Folder`}
                      onClick={() => this.shareFolderLink({id: f.folder_id, ...f})}
                      />
                    }
                  </>)
                }
              </Breadcrumb>
              <Divider/>
              <h2 className="header">Folders</h2>
              <Grid className="w-100 m-0" columns={3}>
                <GridRow>
                  {this.props.folders.loading ? (
                    <div className="d-flex w-100 justify-content-center text-light">
                      Loading...
                    </div>
                  ) : this.props.folders.publicData.length > 0 ? (
                    this.props.folders.publicData.map((f) => (
                      <GridColumn key={f.id} mobile={16} tablet={5} computer={3}>
                        {
                          f.total_used > 0 &&
                          <Label size="small" color="grey" circular title={`Used ${f.total_used} times`} className="label-left-floating">
                            { f.total_used }
                          </Label>
                        }
                        <div
                          className="folder-btn w-100 ui basic button"
                          onClick={
                            (e) => {
                              if (e.ctrlKey) this.handleFolderClick(f.id, f.name)
                            }
                          }
                          onDoubleClick={() => {
                            this.handleFolderClick(f.id, f.name)
                          }}
                        >
                          <div className="d-flex text-truncate">
                            <Icon name='folder'/>
                            <Popup
                              trigger={<span className="text-truncate text-left">{f.name}</span>}
                              inverted
                              hideOnScroll
                            >
                              <div>Name: {f.name}</div>
                              <div>Created At: {moment(f.created).format('YYYY-MM-DD HH:mm:ss')}</div>
                              { f.modified && <div>Last Modified At: {moment(f.modified).format('YYYY-MM-DD HH:mm:ss')}</div> }
                            </Popup>
                            <div className="folder-action">
                              <Dropdown
                                key={`dropdown-${f.id}`}
                                simple
                                closeOnBlur
                                closeOnEscape
                                closeOnChange
                                direction='left'
                                icon={<Icon size="small" name="ellipsis vertical"/>}
                              >
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => this.handleFolderClick(f.id, f.name)}>
                                    <Icon name="arrow up" color="blue"/>
                                    Open
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => this.shareFolderLink(f)}>
                                    <Icon name="copy outline" color="blue"/>
                                    Copy Link
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </GridColumn>
                    ))
                  ) : (
                    <div className="d-flex w-100 justify-content-center text-light">
                      No Folders
                    </div>
                  )}
                </GridRow>
              </Grid>
              <h2 className="header">Files</h2>
              <Grid className="w-100 m-0" columns={3}>
                <GridRow>
                  {this.props.files.loading ? (
                    <div className="d-flex w-100 justify-content-center text-light">
                      Loading...
                    </div>
                  ) : folderID !== null && this.props.files.publicData.length > 0 ? (
                    this.props.files.publicData.map((f) => (
                      <GridColumn
                        mobile={16}
                        tablet={5}
                        computer={3}
                        key={f.id}
                      >
                        {
                          f.total_used > 0 &&
                          <Label size="small" color="grey" circular title={`Used ${f.total_used} times`} className="label-left-floating">
                            { f.total_used }
                          </Label>
                        }
                        <Button
                          className="file-btn w-100"
                          basic
                          onDoubleClick={() =>
                            window.open(f.access_url, "_blank")
                          }
                        >
                          <div>
                            <div className="file-img">
                            {
                              f.thumbnail !== null
                                ? <img src={`https://d2a39xd6ecmbf2.cloudfront.net/drive/thumbnail/${f.thumbnail}`} height='150' width='250'/>
                                : <Icon name={getIconBasedOnFile(f.name)}/>
                            }
                            </div>
                            <div className="d-flex text-truncate file-name">
                              <Popup
                                trigger={
                                  <span className="text-truncate text-left">
                                    {f.name}
                                  </span>
                                }
                                inverted
                                hideOnScroll
                              >
                                <div>Name: {f.name}</div>
                                <div>
                                  Created At:
                                  {moment(f.created).format(
                                    " YYYY-MM-DD HH:mm:ss"
                                  )}
                                </div>
                                {f.modified && (
                                  <div>
                                    Last Modified At:
                                    {moment(f.modified).format(
                                      " YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </div>
                                )}
                              </Popup>
                              <div className="folder-action">
                                <Dropdown
                                  key={`dropdown-${f.id}`}
                                  simple
                                  closeOnBlur
                                  closeOnEscape
                                  closeOnChange
                                  direction="left"
                                  icon={
                                    <Icon
                                      size="small"
                                      name="ellipsis vertical"
                                    />
                                  }
                                >
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        window.open(f.access_url, "_blank")
                                      }
                                    >
                                      <Icon name="arrow up" color="blue" />
                                      Open
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                      navigator.clipboard.writeText(f.access_url)
                                      this.setState({
                                        snackbar: {
                                          open: true,
                                          message: 'Copied to clipboard!',
                                          success: true,
                                          error: false
                                        }
                                      });
                                    }}>
                                      <Icon name="copy outline" color="blue"/>
                                      Copy Link
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        downloadFile(f.access_url, f.name)
                                      }
                                    >
                                      <Icon name="download" color="blue" />
                                      Download
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.setState({
                                          openDownloadAs: true,
                                          fileName: f.name,
                                          downloadURL: f.access_url,
                                        })
                                      }
                                    >
                                      <Icon name="download" />
                                      Download As
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </Button>
                      </GridColumn>
                    ))
                  ) : (
                    <div className="d-flex w-100 justify-content-center text-light">
                      No Files
                    </div>
                  )}
                </GridRow>
              </Grid>
              {
                folderID === null && <>
                  <h2 className="header">Elements</h2>
                  <Grid className="w-100 m-0" columns={3}>
                    <GridRow>
                      {this.props.elements.loading ? (
                        <div className="d-flex w-100 justify-content-center text-light">
                          Loading...
                        </div>
                      ) : this.props.elements.publicData.length > 0 ? (
                        this.props.elements.publicData.map((element) => (
                          <GridColumn
                            mobile={16}
                            tablet={5}
                            computer={3}
                            key={element.id}
                          >
                            <Button
                              className="file-btn w-100"
                              basic
                              onDoubleClick={() =>
                                window.open(element.uri, "_blank")
                              }
                            >
                              <div>
                                <div className="file-img">
                                  <Icon name={getIconBasedOnFile(element.uri)} />
                                </div>
                                <div className="d-flex text-truncate file-name">
                                  <Popup
                                    trigger={
                                      <span className="text-truncate text-left">
                                    {element.name}
                                  </span>
                                    }
                                    inverted
                                    hideOnScroll
                                  >
                                    <div>Name: {element.name}</div>
                                    <div>
                                      Created At:
                                      {moment(element.created).format(
                                        " YYYY-MM-DD HH:mm:ss"
                                      )}
                                    </div>
                                    {element.updated && (
                                      <div>
                                        Last Modified At:
                                        {moment(element.updated).format(
                                          " YYYY-MM-DD HH:mm:ss"
                                        )}
                                      </div>
                                    )}
                                  </Popup>
                                  <div className="folder-action">
                                    <Dropdown
                                      key={`dropdown-${element.id}`}
                                      simple
                                      closeOnBlur
                                      closeOnEscape
                                      closeOnChange
                                      direction="left"
                                      icon={
                                        <Icon
                                          size="small"
                                          name="ellipsis vertical"
                                        />
                                      }
                                    >
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            window.open(element.uri, "_blank")
                                          }
                                        >
                                          <Icon name="arrow up" color="blue" />
                                          Open
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                          navigator.clipboard.writeText(element.uri)
                                          this.setState({
                                            snackbar: {
                                              open: true,
                                              message: 'Copied to clipboard!',
                                              success: true,
                                              error: false
                                            }
                                          });
                                        }}>
                                          <Icon name="copy outline" color="blue"/>
                                          Copy Link
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            downloadYoutubeFile(
                                              element.id,
                                              element.uri
                                            )
                                          }
                                        >
                                          <Icon name="download" color="blue" />
                                          Download
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.setState({
                                              openDownloadAs: true,
                                              fileName: element.name,
                                              downloadURL: element.uri,
                                            })
                                          }
                                        >
                                          <Icon name="download" />
                                          Download As
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </GridColumn>
                        ))
                      ) : (
                        <div className="d-flex w-100 justify-content-center text-light">
                          No Elements
                        </div>
                      )}
                    </GridRow>
                  </Grid>
                </>
              }
            </div>
          </GridColumn>
        </Grid>
        <DownloadAs
          open={openDownloadAs}
          onClose={() =>
            this.setState({
              openDownloadAs: false,
              fileName: null,
              downloadURL: null,
            })
          }
          fileName={fileName}
          fileURL={downloadURL}
        />
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          error={snackbar.error}
          success={snackbar.success}
          onClose={this.resetSnackbar}
        />
      </NavBar>
    );
  }
}

const mapStateToProps = (state) => ({
  files: state.fileReducer,
  folders: state.folderReducer,
  elements: state.youtubeDlReducer,
});

const mapDispatchToProps = {
  fetchPublicFiles: getPublicFiles,
  fetchPublicFolder: getPublicFolder,
  fetchPublicElements: getPublicElements,
  downloadYoutubeFile: downloadElement,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicMedia);
