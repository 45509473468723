import React, {Component} from 'react';
import {
  Icon, Button, Sidebar, Menu, Segment, Image, Dropdown
} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import './navbar.css';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      animation: 'overlay',
      direction: 'left',
      dimmed: false,
    };
  }

  handleAnimationChange = animation => () => this.setState({animation, visible: !this.state.visible})

  handleDimmedChange = (e, {checked}) => this.setState({dimmed: checked})

  setLinkByUser = (isSideBarMenu) => {
    const {auth: {role = 'public'}} = this.props;
    return (
      <>
        {
          !role || role === 'public' ?
            <li>
              <NavLink to="/" className="s-sidebar__nav-link s-slider-nav-link-width" href="#">
                {isSideBarMenu ? <em> <Icon name="user"/> <span>Login</span></em> : <span>Login</span>}
              </NavLink>
            </li>
            :
            <>
              <li>
                <NavLink to="/home" className="s-sidebar__nav-link s-slider-nav-link-width" href="#">
                  {isSideBarMenu ? <em> <Icon name="home"/> <span>Home</span></em> : <span>Home</span>}
                </NavLink>
              </li>
              <li>
                <NavLink to="/media" className="s-sidebar__nav-link s-slider-nav-link-width" href="#">
                  {isSideBarMenu ? <em> <Icon name="file"/> <span>Public Media</span></em> : <span>Public Media</span>}
                </NavLink>
              </li>
              <li>
                <NavLink to="/onsite" className="s-sidebar__nav-link s-slider-nav-link-width" href="#">
                  {isSideBarMenu ? <em> <Icon name="file"/> <span>On Site Media</span></em> : <span>On Site Media</span>}
                </NavLink>
              </li>
              <li>
                <NavLink to="/shared-with-me" className="s-sidebar__nav-link s-slider-nav-link-width" href="#">
                  {isSideBarMenu ? <em> <Icon name="user plus"/> <span>Shared With Me</span></em> : <span>Shared With Me</span>}
                </NavLink>
              </li>
              <li>
                <NavLink to="/youtubeDl" className="s-sidebar__nav-link s-slider-nav-link-width" href="#">
                  {isSideBarMenu ? <em> <Icon name="youtube"/> <span>Youtube</span></em> : <span>Youtube</span>}
                </NavLink>
              </li>
            </>
        }
        {
          role === 'admin' && <li>
            <NavLink to="/weekly-submissions" className="s-sidebar__nav-link" href="#">
              {isSideBarMenu ? <em> <Icon name="chart bar"/> Weekly Submissions</em> : <span>Weekly Submissions</span>}
            </NavLink>
            <NavLink to="/top-performers" className="s-sidebar__nav-link" href="#">
              {isSideBarMenu ? <em> <Icon name="chart bar"/> Top Performers</em> : <span>Top Performers</span>}
            </NavLink>
            <NavLink to="/users" className="s-sidebar__nav-link" href="#">
              {isSideBarMenu ? <em> <Icon name="users"/> Users</em> : <span>Users</span>}
            </NavLink>
          </li>
        }
      </>
    );
  }

  render() {
    const {children, history, auth: {role = 'public', firstName}} = this.props;
    const {
      animation, dimmed, direction, visible
    } = this.state;
    return (
      <>
        <Sidebar.Pushable className="content_main" as={Segment}>
          <Sidebar
            as={Menu}
            animation={animation}
            direction={direction}
            icon="labeled"
            inverted
            vertical
            visible={visible}
            width="thin"
            className="this-is-sidebar"
          >
            <div className="app-logo">
              <Image alt="logo" src="/logo.svg"/>
            </div>
            {
              this.setLinkByUser(true)
            }
          </Sidebar>
          <Sidebar.Pusher className="main_box" dimmed={dimmed && visible}>
            <Segment basic>
              <div className="header-stick-navbar">
                <div className="stick-header-left">
                  <Button onClick={this.handleAnimationChange('push')} size="large"
                          icon={visible ? 'angle double left' : 'angle double right'}/>
                </div>
                <div className={`header-menu-items ${visible ? 'displayNone' : ''}`}>
                  <li><Image src="/logo.svg" style={{height: '45px'}}/></li>
                  {this.setLinkByUser(false)}
                </div>
                <div className="stick-header-right">
                  {
                    role && <Dropdown item text={firstName} fixed="left">
                      <Dropdown.Menu direction="left">
                        <Dropdown.Item icon="sign-out" text="Logout" onClick={() => history.push('/logout')}/>
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </div>
              </div>
              <div className="main-body">
                {
                  children
                }
              </div>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.authReducer
});

export default connect(mapStateToProps, null)(withRouter(NavBar));
