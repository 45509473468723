import React from "react";
import {
  Table,
  Loader,
  Icon,
  Button,
  Select,
  Image,
  Header,
  HeaderContent,
  HeaderSubheader,
  Pagination,
} from "semantic-ui-react";
import { getTopPerformer } from "../../services/reportService";
import Snackbar from "../Snackbar";
import {getIconBasedOnFile} from "../../utility";

import "./style.css"

const PaginationComponent = ({ totalPages, activePage, handlePageChange }) => (
  <Pagination
    totalPages={totalPages}
    activePage={activePage}
    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
    prevItem={{ content: <Icon name="angle left" />, icon: true }}
    nextItem={{ content: <Icon name="angle right" />, icon: true }}
    onPageChange={handlePageChange}
  />
);

class TopPerformer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      total: 0,
      page: 1,
      size: 10,
      sortedField: "total_profit",
      sortDirection: "desc",
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, size, sortDirection, sortedField } = this.state;
    this.setState({loading: true});
    getTopPerformer(page, size, sortedField, sortDirection)
    .then(({data}) => {
      this.setState({loading: false, data: data[1], total: data[0][0].total });
    })
    .catch(err => {
      this.setState({
        loading: false,
        snackbar: {
          open: true,
          message: 'Error getting top performers data!',
          success: false,
          error: true
        },
      })
    });
  }

  handleLimitChange = (e, { value }) => {
    const { page, size } = this.state;
    const diff = Math.ceil(value / size);
    if (size !== value) {
      this.setState({
        size: value,
        page: Math.ceil(page / diff),
      }, () => this.getData());
    }
  };

  handlePageChange = (e, { activePage }) => {
    this.setState({
      page: activePage,
    }, () => this.getData());
  };

  handleSort = (columnName) => {
    let { sortedField, sortDirection } = this.state;
    if (sortedField === columnName) {
      if (sortDirection === "asc") {
        sortDirection = "desc";
      } else {
        sortDirection = "asc";
      }
    } else {
      sortedField = columnName;
      sortDirection = "asc";
    }
    this.setState({
      sortedField,
      sortDirection,
    }, () => this.getData());
  };

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  render() {
    let {
      loading, data, total, snackbar, page, size, sortedField, sortDirection,
    } = this.state;
    return (
      <div className="top-performer-container">
        <center>
          <h1>Top Performers</h1>
        </center>
        <div className="page-content">
          <div className="report-pagination">
            <PaginationComponent
              totalPages={Math.ceil(total / size)}
              activePage={page}
              handlePageChange={this.handlePageChange}
            />
            <span className="records-count">{total} records found!</span>
          </div>
          <div className="pagination">
            <span>Records per page : </span>
            <Select
              placeholder="Records Per Page"
              options={[
                { value: 10, text: "10" },
                { value: 20, text: "20" },
                { value: 50, text: "50" },
                { value: 100, text: "100" },
              ]}
              onChange={this.handleLimitChange}
              defaultValue={10}
              compact
              className="role-dropdown"
            />
          </div>
        </div>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">
                #
              </Table.HeaderCell>
              <Table.HeaderCell>
                Media
              </Table.HeaderCell>
              <Table.HeaderCell>
                Total Profit
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "total_profit"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("total_profit");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Editor
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "owner"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("owner");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="5">
                  <Loader active inline="centered" />
                </Table.Cell>
              </Table.Row>
            ) : data.length ? (
              data.map((d, index) => (
                <Table.Row key={`row-${index}`}>
                  <Table.Cell textAlign="center">{((page - 1) * size) + index + 1}</Table.Cell>
                  <Table.Cell>
                    <Header as='h5' image>
                      {
                        d.link_type === "file" && d.file_thumbnail && <Image src={`https://d2a39xd6ecmbf2.cloudfront.net/drive/thumbnail/${d.file_thumbnail}`} rounded size='small'/>
                      }
                      {
                        d.link_type === "file" && !d.file_thumbnail && <Icon className="file-icon" name={getIconBasedOnFile(d.file_name)}/>
                      }
                      {
                        d.link_type === "folder" && <Icon name="folder" className="folder-icon"/>
                      }
                      <HeaderContent>
                        {d.link_type === "file" ? d.file_name : d.folder_name}
                        <HeaderSubheader>{d.link_type === "file" ? "File" : "Folder"}</HeaderSubheader>
                      </HeaderContent>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>${d.total_profit}</Table.Cell>
                  <Table.Cell>{d.owner}</Table.Cell>
                  <Table.Cell>
                    <Button
                      icon
                      title="Open"
                      onClick={() => window.open(d.link_to_graphic, '_blank')}
                    ><Icon name="arrow up" color="blue"/></Button>
                    <Button
                      icon
                      title="Copy"
                      onClick={() => {
                        navigator.clipboard.writeText(d.link_to_graphic)
                        this.setState({
                          snackbar: {
                            open: true,
                            message: 'Copied to clipboard!',
                            success: true,
                            error: false
                          }
                        });
                      }}
                    ><Icon name="copy outline" color="blue"/></Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="5">
                  No Records Found
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          error={snackbar.error}
          success={snackbar.success}
          onClose={this.resetSnackbar}
        />
      </div>
    );
  }
}

export default TopPerformer;
